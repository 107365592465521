import React from "react";
import { gql, useQuery } from "@apollo/client";
import RuntimeProductImage from "./RuntimeProductImage";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";

const GET_ARTICLES = gql`
  query ($numArticles: Int!) {
    articles(first: $numArticles) {
      edges {
        node {
          id
          title
          excerpt
          handle
          authorV2 {
            name
          }
          publishedAt
          image {
            altText
            width
            height
            originalSrc
            url
          }
        }
      }
    }
  }
`;


const LatestNews = () => {
  const imgData = useStaticQuery(graphql`
    query {
      newsFluidImage: file(relativePath: { eq: "placeholder.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
      blogFluidImage: file(relativePath: { eq: "blog_icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
    }
  `);
const image = getImage(imgData.newsFluidImage);
const blogIcon = getImage(imgData.blogFluidImage);

    const { data } = useQuery(GET_ARTICLES, {
        variables: {
          numArticles: 3,
        },
        fetchPolicy: "no-cache"
      });
  return (
    <div className="flex flex-wrap mx-auto">
      {data?.articles.edges.map((article) => (
        <div className="p-4 md:w-1/3" key={article.node.id}>
          <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <div className="w-full">
              <div className="w-full flex p-2">
                <div className="p-2 ">
                  <GatsbyImage
                    image={blogIcon}
                    alt="author"
                    className="w-10 h-10 rounded-full overflow-hidden"
                  />
                </div>
                <div className="pl-2 pt-2 ">
                  <p className="font-bold">{article.node.authorV2.name}</p>
                  <p className="text-xs">
                    {article.node.publishedAt.substring(0, 10)}
                  </p>
                </div>
              </div>
            </div>
            {article.node.image && (
              <RuntimeProductImage storefrontProduct={article.node.image} />
            )}
            {!article.node.image && (
              <GatsbyImage
                image={image}
                alt="Placeholder"
                className="h-[250px]"
              />
            )}

            <div className="p-4">
              <h2 className="tracking-widest text-xs title-font font-bold text-green-400 mb-1 uppercase ">
                {article.node.title}
              </h2>
              <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                {article.node.excerpt}
              </h1>
              <div className="flex items-center flex-wrap ">
                <Link
                  to={`/blog/${article.node.handle}`}
                  state={{ handle: article.node.handle }}
                  className="text-blue-700  md:mb-2 lg:mb-0"
                >
                  <p className="inline-flex items-center">
                    Read post
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LatestNews;
