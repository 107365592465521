import * as React from "react";
import AboutShop from "../components/AboutShop";
import Banner from "../components/Banner";
import Bestsellers from "../components/BestSellers";
import LatestNews from "../components/LatestNews";
import Layout from "../components/Layout";
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <Banner />
        <div className="container mx-auto">
          <h1 className="text-3xl mt-16 mb-8 font-bold text-center">
            Bestsellers
          </h1>
          {/* best selling products */}
          <Bestsellers />
          <h1 className="text-3xl mt-16 mb-8 font-bold text-center">
            Latest news
          </h1>
          <LatestNews />
          {/* about shop */}
          <AboutShop />
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
