import React from 'react'

const AboutShop = () => {
  return (
    <div className="px-4">
      <h1 className="text-3xl mt-16 mb-8 font-bold text-center">About Us</h1>
      <div className="flex flex-wrap -m-4">
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-200 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
              Lorem ipsum
            </h2>
            <p className="leading-relaxed text-base">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Praesentium tenetur
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-200 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <circle cx="6" cy="6" r="3"></circle>
                <circle cx="6" cy="18" r="3"></circle>
                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
              </svg>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
              Lorem ipsum
            </h2>
            <p className="leading-relaxed text-base">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Praesentium tenetur
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-200 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
              Lorem ipsum
            </h2>
            <p className="leading-relaxed text-base">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Praesentium tenetur
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutShop