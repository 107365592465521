import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";

const Banner = () => {
  const imgData = useStaticQuery(graphql`
    query {
      businessFluidImage: file(relativePath: { eq: "business.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
      cyberFluidImage: file(relativePath: { eq: "cyber.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
      ecommerceluidImage: file(relativePath: { eq: "ecommerce.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
    }
  `);

    const businessImage = getImage(imgData.businessFluidImage);
    const cyberImage = getImage(imgData.cyberFluidImage);
    const ecommerceImage = getImage(imgData.ecommerceluidImage);

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: false }}
    >
      <SwiperSlide>
        <GatsbyImage
          style={{ height: `calc(100vh - 128px)` }}
          className={`object-fit-cover w-screen`}
          image={businessImage}
          loading="eager"
          alt="Business image"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <Link
            to="/catalog"
            className="px-4 py-2 mr-4 text-white uppercase bg-gray-800 border-2 border-transparent text-md hover:bg-gray-900"
          >
            Shop now
          </Link>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <GatsbyImage
          className="h-[calc(100vh-128px)] object-fit-cover w-screen"
          image={cyberImage}
          alt="Cyber deal"
        />
      </SwiperSlide>
      <SwiperSlide>
        <GatsbyImage
          className="h-[calc(100vh-128px)] object-fit-cover w-screen"
          image={ecommerceImage}
          alt="Ecommerce"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Banner;
